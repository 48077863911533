import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// 1. 引入你需要的组件
import { DropdownMenu, DropdownItem } from 'vant';
import { Image as VanImage } from 'vant';

import { Rate } from 'vant';
import { Icon } from 'vant';
import '@/common/css/base.less'
// 2. 引入组件样式
import 'vant/lib/index.css';
const app = createApp(App);
// 3. 注册你需要的组件
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Rate)
app.use(Icon)
app.use(VanImage);
app.use(router);
app.mount('#app');
