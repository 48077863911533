<template>
    <div class="footerContainer">
        <div class="footer-policy">
            <!-- <a href="privacy-policy.html" class="footer-policy-btn">Privacy</a> -->
            <a href="#" class="footer-policy-btn">Privacy</a>
            <a href="#" class="footer-policy-btn">Cookies</a>
        </div>
        <div>Copyright © 2023 All Rights Reserved</div>
    </div>
</template>
<script>
//  export default{
//     name:'ZFooter'
//  }
</script>
<style lang="less" scoped>
    .footerContainer{
        // position: fixed;
        // bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 60px;
        // margin-top: 10px;
        background: rgb(37, 199, 255);
        background: linear-gradient(90deg, rgba(37, 199, 255, 1) 0%, rgb(0, 74, 255) 100%);
        z-index: 2;
        color: #fff;
        .footer-policy{
            display: flex;
            a{
                display: block;
                text-align: center;
                width: 100px;
                color: #ffffff;
                &:first-child{
                    border-right: 1px solid #ffffff;
                }
            }
        }
    }
</style>