<template>
  <div class="header-container">
    <div class="header-main">
      <div @click="goHome" v-if="showIcon">
          <van-icon name="arrow-left" size="30"/>
      </div>
      <div class="title">Artificial Intelligence</div>
       <div class="list-icon" v-if="showIcon"  @click="toggleDropdown">
           <p></p>
           <p></p>
           <p></p>
      </div>

    </div>
    <!-- 弹出层 -->
    <div class="header-popup" v-if="showPopup">
      <input type="text" v-model="selectedId" style="display: none;">
      <ul>
        <li v-for="option in menuData" :key="option.id" @click="selectOption(option)"  :class="{ 'active': selectedId === option.id }">
          <span>{{option.name}}</span>
          <van-icon name="success" v-if="selectedId === option.id " />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import { defineProps,defineEmits,ref} from 'vue';
  import productData from '@/pages/mock/product.js'

// console.log(productData,999)
  const router = useRouter()

  const props = defineProps({
    showIcon:{
      type: Boolean,
      default:true
    },
    selectedId: {
      type:Number,
      default:1
    }
    
  })
  let selectedId = ref(Number(props.selectedId));
  const emit = defineEmits(['toggleProduct']);

  const menuData = productData.data;
  // const menuData = productData;

  const showPopup = ref(false)

  const toggleDropdown = ()=>{
    showPopup.value = !showPopup.value;

  }
  const goHome = ()=>{
    router.push('/home')
  }

  // 下拉选择方法
  const selectOption = (option)=>{
    selectedId.value = option.id;
    showPopup.value = false;
    emit('toggleProduct',option.id)
  }
</script>

<style lang="less" scoped>
  @keyframes popup {
    to {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  .header-container{
    position: relative;
    width: 100vw;
    .header-main{
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 10px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      background: linear-gradient(90deg, rgba(37, 199, 255, 1) 0%, rgb(0, 74, 255) 100%);
      box-sizing: border-box;

    }
    .header-popup{
        position: absolute;
        width: 100%;
        background-color: #fff;
        height: fit-content;
        z-index: 3;
        // max-height: 500px;
        // overflow: scroll;
        transition: top 2s ease-out;
        li{
          padding:15px 0;
          text-align: center;
          font-size: 14px;
          border-bottom: 1px solid #e9e9e9;
          span{
            margin-right: 5px;
          }
        }
        .active{
          color: #027DFF;
        }
    }
    .list-icon{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
        p{
          width: 30px;
          height: 4px;
          background-color: #FFF;
          border-radius: 2px;
          &:nth-child(2){
            margin: 6px 0;
          }
        }
    }
    .title{
      flex: 1;
      text-align: center;
    }
  }
</style>
