<template>
    <HeaderTop @toggleProduct="toggleProduct" :selected-id="Number(query.id)"></HeaderTop>
    <div class="detailContainer">
        <div class="detail-content">
            <!-- <div>{{ detailData.name }}</div> -->
            <div class="content-title">
                <div class="content-img">
                    <img v-if="detailData.image" :src="require('@/assets/' + detailData.image)" alt="" />
                    <!-- <img src="@/static/images/chatGPT.png" alt="" /> -->
                </div>
            </div>
            <div class="sub-title">
                <div class="free" v-if="detailData.free">Free Trial</div>
                <div v-for="(it,idx) in detailData.subTitle" :key="idx">{{ it }}</div>
                <!-- <div>United States of America</div>
                <div>2012</div> -->
            </div>
            <div class="content-tags">
                <span class="tag" v-for="(tagIt,tagIdx) in detailData.tags" :key="tagIdx">{{ tagIt }}</span>
                <!-- <span class="tag">Machine Learning Software</span>
                <span class="tag">Deep Learning Software</span>
                <span class="tag">AutoML software</span> -->
            </div>
            <div class="content-description">
                <div class="desc-language" >
                    Languages Supported : {{ detailData.languages || '' }}
                </div>
                <div class="desc-article">
                    {{ detailData.desc }}
                </div>
            </div>
            <div>
                <MyRate></MyRate>
            </div>
            <div class="content-rate">
                <van-rate color="#027DFF"  void-color="#027DFF" :size="16" v-model="detailData.rate" readonly allow-half/>
                <span>({{ detailData.rate }})</span>
            </div>
            <div class="content-introduce">
                <template  v-for="(item,index) in detailData.content" :key="index">
                    <h4 v-if="item.tag === 'h4'">{{ item.text }} </h4>
                    <h5 v-else-if="item.tag === 'h5'">{{ item.text }}</h5>
                    <div v-else-if="item.tag === 'div'">{{ item.text }}</div>
                    <p :class="item.class || ''" v-else-if="item.tag === 'p'">{{ item.text }} </p>
                    <!-- <div v-else-if="item.tag === 'p'">
                            <p>{{ item.text }} </p>
                    </div> -->
                </template>
            </div>
        </div>

        
    </div>
</template>
<script setup>
    import HeaderTop from '../components/HeaderTop.vue'
    import { reactive, ref } from 'vue';
    import MyRate from '../components/MyRate.vue';
    import productData from '../mock/product.js'
    import { useRouter,useRoute } from 'vue-router';


    const route = useRoute()
    const router = useRouter()
    const query = route.query
    // console.log(productData,'detail')

    let detailData = reactive({})
  
    const filterDetail = (id)=>{
        let result = reactive({})
        productData.data.forEach(item=>{
            if(item.id === Number(id)){
                result =  item
            }
        })
        return result

    }
    detailData = filterDetail(query.id)
    
    // const rate = ref(4.9)
    const toggleProduct = (id)=>{
        detailData = filterDetail(id)
        router.push({ path:'/detail', query: { id: id } })
    }
</script>
<style lang="less" scoped>
 
    .detailContainer{
        // width: 100vw;
        // max-width: 700px;
        // min-height: 82vh;
        max-width: 700px;
        margin:0 auto;
        padding: 18px;
        box-sizing: border-box;
        .detail-content{
            background-color: #fff;
            box-shadow: 0 3px 25px 0 rgb(0 0 0 / 10%);
            padding: 18px;
            border-radius: 12px;
            .content-title{
                display: flex;
                justify-content: center;
                align-items: center;
                // border:1px solid red;
                .content-img{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 130px;
                    height: 130px;
                    padding: 10px;
                    box-shadow: 0 3px 25px 0 rgb(0 0 0 / 10%);
                    box-sizing: border-box;
                    border-radius: 6px;
                }
            }
            .sub-title{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 20px 0;
                font-size: 12px;
                div{
                    border-radius: 4px;
                    padding: 3px;
                    color: #b9b9b9;
                    margin-bottom: 5px;
                }
                .free{
                    width: 100px;
                    text-align: center;
                    border-radius: 5px;
                    border: 1px solid #004aff;
                    color: #004aff;
                }
            }
            .content-tags{
                display: flex;
                flex-direction: column;
                align-items: center;
                .tag{
                    width: fit-content;
                    background-color: #afafaf;
                    padding: 5px;
                    border-radius: 4px;
                    font-size: 12px;
                    color: #ffffff;
                    margin-right: 4px;
                    margin-bottom: 4px;
                }
            }
            .content-description{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                min-height: 100px;
                // border:1px solid red;
                .desc-language{
                    font-size: 13px;
                    text-align: center;
                    color: #004aff;
                }
                .desc-article{
                    width: 280px;
                    font-size: 13px;
                    text-align: center;
                }
            }
            .content-rate{
                // text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
            }
            .content-introduce{
                font-size: 14PX;
                line-height: 20px;
                div{
                    margin-bottom: 10px;
                }
                .mgbt10{
                    margin-bottom: 10px;
                }
                h5{
                    margin-bottom: 10px;
                    color: #8d8d8d;
                }
                h4{
                    margin-bottom: 10px;
                }
            }
        }
    }
</style>