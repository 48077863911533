<template>
    <HeaderTop :show-icon="false"></HeaderTop>
    <div class="homeContainer">
        <div class="card-product" v-for="item in listData" :key="item.id">
            <div class="product-preview">
                <div class="top-left">
                    <div class="product-img">
                        <!-- <img src="@/assets/chatGpt.png" alt="" /> -->
                        <img v-if="item.image" :src="require('@/assets/' + item.image)" alt="" />
                    </div>
                </div>
                <div class="top-right">
                    <div class="product-title"> 
                        <h3>{{ item.name }}</h3>
                        <div class="star-rate">
                            <van-rate color="#027DFF" void-color="#027DFF" :size="14" v-model="item.rate" readonly allow-half/>
                            <span>({{ item.rate }})</span>
                        </div>
                    </div>
                    <!-- <div>评分占位</div> -->
                     <div class="progress-rate">
                         <MyRate :rateData="item"></MyRate>
                     </div>
                </div>
            </div>
            <div class="product-desc">
                <span>{{ item.desc }}</span>

            </div>
            <div class="bottom-btn" @click="goDetail(item.id)">
                <!-- <a href="#"></a> -->
                 <div>View Profile</div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import HeaderTop from '../components/HeaderTop.vue'
    import MyRate from '../components/MyRate.vue';
    // import Test from '../components/test.vue'
    // import CompactProgressBar from '../components/CompactProgressBar.vue';
    import productData from '../mock/product.js'
    // import { ref } from 'vue';
    import { useRouter } from 'vue-router';

    const router = useRouter()
    const listData = productData.data;

    const goDetail = (id)=>{
        router.push({ path:'/detail', query: { id: id } })
    }

</script>
<style lang="less" scoped>
//   @import 'main.less'
.homeContainer{
    // width: 100vw;
    // min-height: 84vh;
    // padding: 18px 18px 78px;
    max-width: 700px;
    margin:0 auto;
    padding: 18px 18px 0;
    box-sizing: border-box;
    // border:1px solid green;
    .card-product{
        padding: 18px;
        margin-bottom: 16px;
        background-color: #fff;
        box-shadow: 0 3px 25px 0 rgb(0 0 0 / 10%);
        border-radius: 12px;
        .product-preview{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .top-left{
                display: flex;
                align-items: center;
                .product-img{
                    width: 110px;
                    height: 110px;
                    padding: 0 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    box-shadow: 0 3px 25px 0 rgb(24 24 24 / 6%);
                    box-sizing: border-box;
                }
            }
            .top-right{
                // border:1px solid red;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex:1;
                margin-left: 20px;
                color: #333;
                .product-title{
                    margin-bottom: 16px;
                    font-size: 16px;
                    h3{
                        font-weight: 400;
                    }
                    .star-rate{
                        font-size: 12px;
                        color: #b9b9b9;
                        span{
                            margin-left: 5px;
                        }
                    }
                }
                .progress-rate{
                    width: 100%;
                }
                // .product-rate{
                //     color: #919191;
                //     li{
                //         border:1px solid red;
                //         // flex-wrap: wrap;
                //         margin-bottom: 10px;
                //     }
                //     .rate-title{
                       
                //         &>span{
                //             margin-left: 6px;
                //         }
                //     }
                // }
            
            }
        }
        .product-desc{
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            // padding: 10px 20px 0;
            padding-top: 10px;
            border-top: 1px solid #d9d9d9;
            box-sizing: border-box;
            font-size: 12px;
            color: #919191;
            span{
                width: 262px;
            }
        }
        .bottom-btn{
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 32px;
                font-size: 12px;
                font-weight: 900;
                border-radius: 8px;
                background: rgb(37, 199, 255);
                background: linear-gradient(90deg, rgba(37, 199, 255, 1) 0%, rgba(0, 74, 255, 1) 100%);
                color: #fff;
            }
        }
    }

}
</style>