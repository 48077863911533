import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/home/main.vue'
import DetailPage from '../pages/detail/main.vue'
const routes = [
    {
        path:'/',
        redirect: '/home'
    },
    {
        path:'/home',
        component: HomePage
    },
    {
        path:'/detail',
        component: DetailPage
    }
]
const router = createRouter({

    history: createWebHistory(),
  
    routes,
  
  });
export default router;