export default {
    data: [
        {
            "id": 1,
            "name": "ChatGPT",
            "free": true,
            "subTitle": [
                "Product by ChatGPT",
                "United States of America",
                "2022"
            ],
            "languages":"English,Chinese",
            "rate": 4.9,
            "image": "ChatGPT.png",
            "tags": [
                "Imitate human interlocutors",
                "Write and debug computer programs",
                "Artistic creation",
                "Emulating Linux system",
                "Write poems and lyrics"
            ],
            "desc": "An artificial intelligence chatbot named ChatGPT was created by OpenAI and released in November 2022",
            "content": [
                {
                    "tag": "div",
                    "text": "An artificial intelligence chatbot named ChatGPT was created by OpenAI and released in November 2022. It was developed on top of the OpenAI large language models (LLMs) from the GPT-3.5 and GPT-4 families, and it has been fine-tuned (a method of transfer learning) using both supervised and reinforcement learning strategies."
                },
                {
                    "tag": "div",
                    "text": "On November 30, 2022, ChatGPT was introduced as a prototype. It soon gained popularity for its thorough responses and clear responses in a variety of subject areas. Yet, a tag flaw in it has been recognized as its uneven factual accuracy.After ChatGPT was released, it was predicted that OpenAI will be textd at US$29 billion in 2023."
                },
                {
                    "tag": "div",
                    "text": "Based on GPT-3.5, ChatGPT was first made available in November 2022; GPT-4, the most recent OpenAI model, was made available to ChatGPT Plus customers on March 14, 2023."
                },
                {
                    "tag": "div",
                    "text": "ChatGPT is a GPt-based natural language processing model for generating natural conversations. It was developed by OpenAI researchers. The model is trained using huge natural language processing datasets and advanced machine learning algorithms to produce expressive and coherent text."
                },
                {
                    "tag": "div",
                    "text": "ChatGPT can be used in various application scenarios, such as intelligent customer service and voice assistant. It can generate responses based on user input. These responses may be short answers, long explanations, or initiating a new topic. ChatGPT's training data set is rich and diverse, which can greatly improve the quality of the generated language."
                },
                {
                    "tag": "div",
                    "text": "ChatGPT can also be adjusted to generate domain-specific text. In healthcare, for example, it could help doctors and patients have conversations, provide customized advice and information to patients, and even train models to fight depression."
                },
                {
                    "tag": "div",
                    "text": "In short, the use of ChatGPT makes natural conversation generation more natural, efficient and personalized, and has good application potential for fields that require a lot of conversation interaction, such as online customer service and intelligent education."
                }
            ]

        },
        {
            "id": 2,
            "name": "Teachable Machine",
            "languages":"English",
            "free": true,
            "subTitle": [
                "Product by Teachable Machine",
                "A machine learning tool developed by Google",
                "2021"
            ],
            "rate": 3.1,
            "image": "TeachableMachine.png",
            "tags": [
                "AutoML software",
                "Machine Learning Software",
                "Deep Learning Software",
                "No code AI tool / software",
                "MLOps Platforms",
                "AI/ML Development Services", 
                "AI/ML Development Services",
                "Data science / ML / AI platform " 

            ],
            "desc": "Teachable Machine is an easy-to-use machine learning platform developed by Google that quickly creates and trains machine learning models with images, sounds, and motions",
            "content": [
                {
                    "tag": "div",
                    "text": "Teachable Machine is an easy-to-use machine learning platform developed by Google that quickly creates and trains machine learning models with images, sounds, and motions. This platform doesn't require any coding skills, there's a huge selection of images and words to choose from, and it's incredibly easy to use."
                },
                {
                    "tag": "div",
                    "text": "The Teachable Machine has built-in intelligent algorithms that can recognize information such as images, sounds, and movements, and has strong recognition accuracy."
                },
                {
                    "tag": "div",
                    "text": "Users can use the platform to train or make their own machine learning models, such as image classification, gesture recognition and speech recognition, and its application scenarios are very wide."
                },
                {
                    "tag": "div",
                    "text": "During training, the platform will provide real-time feedback to support voluntary parameter adjustments and modifications, making it easier for users to adjust the model to meet changing task requirements."
                },
                {
                    "tag": "div",
                    "text": "In addition to ease of use and identification accuracy, Teachable Machine enables efficient management and protection of user data. The platform allows users to privacy training data, protect data security, and prevent data from being leaked."
                },
                {
                    "tag": "div",
                    "text": "Teachable Machine also provides the ability to run cross-platform, supporting the use of trained models on multiple different devices, such as smartphones, computers, and so on."
                },
                {
                    "tag": "div",
                    "text": "All in all, Teachable Machine is an integrated and easy-to-use machine learning platform developed by Google that takes the hassle out of writing code and makes it accessible to anyone involved in technology development."
                },
                {
                    "tag": "div",
                    "text": "With its powerful capabilities and utility, the Teachable Machine is ideal for many businesses and individuals who require rapid development of custom speech, image, and motion recognition models."
                },
                {
                    "tag": "h5",
                    "text": "Teachable Machine"
                },
                {
                    "tag": "div",
                    "text": "Develop a computer's ability to detect your own movements, noises, and poses."
                },
                {
                    "tag": "h5",
                    "text": "What is Teachable Machine?"
                },
                {
                    "tag": "div",
                    "text": "A web-based application called Teachable Machine allows building machine learning models quick, simple, and available to everyone."
                },
                {
                    "tag": "h5",
                    "text": "What can I use to teach it?"
                },
                {
                    "tag": "div",
                    "text": "Teachable Machine can record examples in real time or from files. That shows respect for how you do your job. Even better, you can decide to use it exclusively on your device, preventing any webcam or microphone data from ever entering your computer."
                }
            ]
        },
        {
            "id": 3,
            "name": "MonkeyLearn",
            "free": true,
            "subTitle": [
                "An artificial intelligence tool"
            ],
            "languages":"English",
            "rate": 4.5,
            "image": "MonkeyLearn.jpg",
            "tags": [
                "AutoML software",
                "Artificial Intelligence (AI) Companies",
                "Data science / ML / AI platform",
                "Machine Learning Software"
            ],
            "desc": "MonkeyLearn is a natural language processing (NLP) company that provides an easy-to-use NLP platform for a variety of application scenarios such as data analysis, automated text classification, sentiment analysis",
            "content": [
                {
                    "tag": "div",
                    "text": "MonkeyLearn is a natural language processing (NLP) company that provides an easy-to-use NLP platform for a variety of application scenarios such as data analysis, automated text classification, sentiment analysis, and keyword extraction. MonkeyLearn is developed on the basis of traditional machine learning algorithms and the latest deep learning techniques to train models and make predictions based on text data that is already available."
                },
                {
                    "tag": "div",
                    "text": "It is unique in that it does not require users to have a background with extensive programming and statistical knowledge, nor does it require them to build their own algorithms or purchase expensive machine learning development software."
                },
                {
                    "tag": "div",
                    "text": "One of the main features of MonkeyLearn is its extensibility and ease of use. Through its API interface, users can easily integrate it into existing applications and add natural language processing to business processes."
                },
                {
                    "tag": "div",
                    "text": "MonkeyLearn can handle multiple languages, including English, Spanish, Chinese, and more, providing a variety of models for users to perform specific entity categories, sentiment analysis, keyword extraction, and other operations, which are very useful when processing administrative data."
                },
                {
                    "tag": "div",
                    "text": "MonkeyLearn also provides extensive resources and documentation to help users quickly understand and use their natural language processing API."
                },
                {
                    "tag": "div",
                    "text": "In summary, MonkeyLearn is a feature-rich and easy-to-use natural language processing application platform that combines the latest machine learning algorithms and deep learning techniques with a user-friendly interface and API interface to provide enterprises and developers with highly customized and easy-to-customize NLP solutions."
                },
                {
                    "tag": "div",
                    "text": "Cleaning, labeling, and visualizing client feedback is made simple by MonkeyLearn, all in one location."
                },
                {
                    "tag": "h5",
                    "text": "Instant Data Visualizations & Detailed Insights"
                },
                {
                    "tag": "div",
                    "text": "Create intelligent apps more quickly using pre-made AI models and strong machine learning. To access our API, register now and get started."
                },
                {
                    "tag": "h5",
                    "text": "For Data Scientists"
                },
                {
                    "tag": "div",
                    "text": "A studio that combines text analysis and data visualization. Run an analysis on your data to get quick insights."
                },
                {
                    "tag": "h5",
                    "text": "Pre-built & Custom Machine Learning Models"
                },
                {
                    "tag": "div",
                    "text": "Utilize pre-built machine learning models or create and train your own - without writing any code."
                },
                {
                    "tag": "h5",
                    "text": "Simplify Text Analytics with Business Templates"
                },
                {
                    "tag": "div",
                    "text": "Learn about our templates, which have pre-built text analysis models and dashboards and are designed for various business contexts."
                }
            ]
        },
        {
            "id": 4,
            "name": "DataRobot",
            "free": true,
            "subTitle": [
                "Product by DataRobot",
                "United States of America",
                "2012"
            ],
            "languages":"English",
            "rate": 4.5,
            "image": "DataRobot.png",
            "tags": [
                "Machine Learning Software",
                "AutoML software",
                "Deep Learning Software", 
                "No code AI tool / software",
                "MLOps Platforms",
                "AI/ML Development Services",
                "Data science / ML / AI platform",
                "Predictive Analytics Software", 
                "Artificial Intelligence (AI) Companies"
                
            ],
            "desc": "DataRobot is an artificial intelligence (AI) company that has developed an automated machine learning platform that helps businesses quickly build, deploy, and manage machine learning models",
            "content": [
                {
                    "tag": "div",
                    "text": "DataRobot is an artificial intelligence (AI) company that has developed an automated machine learning platform that helps businesses quickly build, deploy, and manage machine learning models."
                },
                {
                    "tag": "div",
                    "text": "DataRobot provides users with a visual interface that allows users to simplify complex machine learning processes and complete tasks such as data preparation, feature engineering, model selection and deployment with just drag and drop. The platform also uses automated feature selection, model tuning, and deployment capabilities to improve the efficiency and accuracy of machine learning."
                },
                {
                    "tag": "div",
                    "text": "DataRobot provides a large number of pre-modeled models and adaptive algorithms that users can customize and train according to their needs. These algorithms can be used in a wide range of applications, such as finance, retail, insurance, healthcare, etc., and users can create and customize models according to their needs."
                },
                {
                    "tag": "div",
                    "text": "DataRobot also provides users with data set management, model versioning, and a comprehensive visualization and analysis platform to help them better manage and update machine learning models to stay in tune with business needs."
                },
                {
                    "tag": "div",
                    "text": "DataRobot is a powerful AI platform that enables businesses to build and deploy machine learning models faster and more accurately. DataRobot's automation capabilities make the platform more suitable for enterprises, especially those without dedicated data scientists and machine learning experts."
                },
                {
                    "tag": "h5",
                    "text": "Value at Manufacturing Scale"
                },
                {
                    "tag": "div",
                    "text": "To quickly reap the benefits of AI, iterate and experiment with code-first or no-code choices. Collaboration and productivity are improved through explainable automation of best practices, guardrails, and cutting-edge models."
                },
                {
                    "tag": "h5",
                    "text": "Experience with Collaborative Experimentation"
                },
                {
                    "tag": "div",
                    "text": "Release models from DataRobot or other parties into production with essential ML and automation features. Use DevOps technologies and techniques to improve communication between the data science and IT operations teams."
                },
                {
                    "tag": "h5",
                    "text": "Guaranteed compliance and governance"
                },
                {
                    "tag": "div",
                    "text": "Manage models to the highest standards, automatically document model behavior, and automate and adapt model performance testing. Manage access and production model changes to comply with rules and lower total risk."
                }
            ]
        },
        {
            "id": 5,
            "name": "Clarifai",
            "free": true,
            "subTitle": [
                "Product by Clarifai AI Platform"
            ],
            "languages":"English",
            "rate": 4.8,
            "image": "Clarifai.png",
            "tags": [
                "AutoML software",
                "Machine Learning Software",      
                "MLOps Platforms",
                "AI/ML Development Services",        
                "Artificial Intelligence (AI) Companies"
            ],
            "desc": "Clarifai is an artificial intelligence company that has developed an AI platform that allows developers, businesses, and institutions to build and manage image and video recognition applications.",
            "content": [
                {
                    "tag": "div",
                    "text": "Clarifai is an artificial intelligence company that has developed an AI platform that allows developers, businesses, and institutions to build and manage image and video recognition applications. The goal of the platform is to help developers quickly implement a variety of visual AI application scenarios to make the analysis of images and videos smarter, more efficient and accurate."
                },
                {
                    "tag": "div",
                    "text": "Clarifai provides developers with the application and development tools to take the latest AI algorithms and provides an easy-to-use user interface for the user to visualize the model training workflow without requiring any programming or professional machine learning background. Clarifai's core algorithms are based on Convolutional neural networks (CNNS), which are able to automatically learn patterns from user-supplied data and apply them to new, unknown data."
                },
                {
                    "tag": "div",
                    "text": "The Clarifai AI Platform can be used for a variety of application scenarios, including image processing, product classification, visual search, content monitoring, and brand safety. Compared with traditional visual application development, Clarifai can significantly reduce the technical complexity and development costs, so that visual AI applications can quickly enter the market and better serve users."
                },
                {
                    "tag": "div",
                    "text": "In short, the Clarifai AI platform is a powerful and easy-to-use visual AI application platform that can meet the different needs of enterprises and developers, accelerating the transformation of images and videos from data to practical applications."
                },
                {
                    "tag": "div",
                    "text": "Developers, data scientists, and no-code operators can use the most straightforward deep learning AI platform."
                },
                {
                    "tag": "h5",
                    "text": "For Developers"
                },
                {
                    "tag": "div",
                    "text": "Create intelligent apps more quickly using pre-made AI models and strong machine learning. To access our API, register now and get started."
                },
                {
                    "tag": "h5",
                    "text": "For Data Scientists"
                },
                {
                    "tag": "div",
                    "text": "For data preparation, insight discovery, visual exploration, and modeling, use a single data workspace that allows for endless customization. Without Dev Ops, ML Ops."
                },
                {
                    "tag": "h5",
                    "text": "For Enterprises"
                },
                {
                    "tag": "div",
                    "text": "Make sense of your large data using AI. promote successful expansion. increasing output. Make using AI a fundamental capability in your company."
                },
                {
                    "tag": "h5",
                    "text": "For Government"
                },
                {
                    "tag": "div",
                    "text": "Use a single, infinitely customizable data workspace for modeling, insight discovery, visual exploration, and data preparation. Dev Ops is not ML Ops."
                }
            ]

        },
        {
            "id": 6,
            "name": "Weaverse",
            "free": true,
            "subTitle": [
                "Product by Hybe Corporation"
            ],
            "languages":"English,Korean",
            "rate": 4.6,
            "image": "Weaverse.png",
            "tags": [
                "A community for artists and fans",
                "Communicate in real time on Weverse LIVE",   
                "Use Weverse DM to communicate closely with artists",  
                "Weverse has added a new Weverse Shop feature",
                "Express your feelings with fan letters"
            ],
            "desc": "Weverse is a South Korean mobile app and web platform created by South Korean entertainment company Hybe Corporation.  The app specializes in hosting multimedia content, the sale of artist-related merchandise, content subscription, and artist-to-fan communications for artists.",
            "content": [
                {
                    "tag": "div",
                    "text": "Weverse is a South Korean mobile app and web platform created by South Korean entertainment company Hybe Corporation.  The app specializes in hosting multimedia content, the sale of artist-related merchandise, content subscription, and artist-to-fan communications for artists."
                },
                {
                    "tag": "div",
                    "text": "Weverse hosts a variety of free and paid content including educational and entertainment videos, Instagram Story-style updates, and artist-to-fan interactions and communities for users to connect with each other.   The app is also used to publish official statements by Hybe (formerly Big Hit Entertainment) on behalf of artists signed with its labels."
                },
                {
                    "tag": "div",
                    "text": "The software was developed by Hybe's technology subsidiary Weverse Company (formerly beNX). As of 2023, Weverse has over 10 million monthly users."
                },
                {
                    "tag": "h4",
                    "text": "A community for artists and fans"
                },
                {
                    "tag": "div",
                    "text": "View artists' daily moments, leave comments and cheer"
                },
                {
                    "tag": "div",
                    "text": "Are you unable to understand what fans and artists around the world are posting due to language barriers? Use the automatic translation function to overcome language barriers!"
                },
                {
                    "tag": "h4",
                    "text": "Communicate in real time on Weverse LIVE"
                },
                {
                    "tag": "div",
                    "text": "You can enjoy your favorite artists' live broadcasts in real time on Weverse"
                },
                {
                    "tag": "div",
                    "text": "Not only can you send real-time chats during live broadcasts, you can also express your feelings with 'love'"
                },
                {
                    "tag": "h4",
                    "text": "Use Weverse DM to communicate closely with artists"
                },
                {
                    "tag": "div",
                    "text": "Subscribe to Weverse DM and chat with artists through private messages"
                },
                {
                    "tag": "div",
                    "text": "You can subscribe after purchasing Weverse's digital currency Jelly"
                },
                {
                    "tag": "h4",
                    "text": "Weverse has added a new Weverse Shop feature"
                },
                {
                    "tag": "div",
                    "text": "In the Weverse App, you can directly connect to the Weverse Shop to purchase official products and albums, view order records and real-time status, etc"
                },
                {
                    "tag": "h4",
                    "text": "Express your feelings with fan letters"
                },
                {
                    "tag": "div",
                    "text": "Dress up fan letters by yourself to convey your sincerity to artists"
                },
                {
                    "tag": "div",
                    "text": "Use the various templates and dressing functions provided by the fan letter editor to create your own fan letters"
                },
                {
                    "tag": "h4",
                    "text": "The joy of collecting fan activities"
                },
                {
                    "tag": "div",
                    "text": "You can get badges and Collectibles through fan activities"
                },
                {
                    "tag": "div",
                    "text": "Complete various activity tasks to get corresponding badges, and collect Collectibles containing different pictures and videos"
                },
                {
                    "tag": "div",
                    "text": " In 'My Collection', you can view all badges and Collectibles you have obtained in each community"
                },
                {
                    "tag": "h4",
                    "text": "Rich and colorful video content"
                },
                {
                    "tag": "div",
                    "text": "In addition to the official video content of artists, you can also enjoy Weverse exclusive video content!"
                },
                {
                    "tag": "div",
                    "text": "Enjoy a variety of video content with subtitles in different languages"
                },
                {
                    "tag": "h4",
                    "text": "Exclusive content and benefits for members"
                },
                {
                    "tag": "div",
                    "text": "·Enjoy exclusive video content only available to members"
                }
            ]
        },
        {
            "id": 7,
            "name": "Blinkist",
            "free": true,
            "subTitle": [
                "Product by Blinks Labs"
            ],
            "languages":"English",
            "rate": 4.8,
            "image": "Blinkist.png",
            "tags": [
                "The world of knowledge in your pocket",
                "Get key insights from over 6500 best-selling books and podcasts",
                "Plan the space (your learning playlist) to achieve your goals",
                "Enjoy expert crafted summaries of key ideas and be passionate",
                "Blinkist is a fast and convenient way to learn"
            ],
            "desc": "Through our summaries of today's most transformative books, you can get powerful ideas in minutes instead of spending hours or days.",
            "content": [
                {
                    "tag": "div",
                    "text": "Through our summaries of today's most transformative books, you can get powerful ideas in minutes instead of spending hours or days."
                },
                {
                    "tag": "div",
                    "text": "We provide over 6500 key insights summaries in audio or text format, as well as personal guidance provided by our experts and curators through themes. Whatever you want to learn, you can find it here."
                },
                {
                    "tag": "div",
                    "text": "29+million people have trusted Blinkist to help them grow and maximize their potential and time."
                },
                {
                    "tag": "div",
                    "text": "Join us and learn smarter, not harder.Get key insights from over 6500 best-selling books and podcasts."
                },
                {
                    "tag": "div",
                    "text": "Blinks: Read and listen to book summaries of popular books in 15 minutes"
                },
                {
                    "tag": "div",
                    "text": "Short film: In depth understanding of the core of transformative podcasts"
                },
                {
                    "tag": "div",
                    "text": "Enhance your professional skills through the best business books and podcasts"
                },
                {
                    "tag": "div",
                    "text": "Grow together with the most influential self-improvement titles Discover new perspectives through best-selling books on economics, science, history, and culture"
                },
                {
                    "tag": "h4",
                    "text": "Grow together with the most influential self-improvement titles"
                },
                {
                    "tag": "div",
                    "text": "Discover new perspectives through best-selling books on economics, science, history, and culture"
                },
                {
                    "tag": "h4",
                    "text": "Find inspiration from the latest trends and ideas in your professional field"
                },
                {
                    "tag": "div",
                    "text": "No matter where you are, you can grow: enjoy audio and text Listen to the audio version of our book insights and easily integrate learning into your daily life"
                },
                {
                    "tag": "h4",
                    "text": "Hands free learning through automatic playback in the gym, in the car, or while doing household chores"
                },
                {
                    "tag": "div",
                    "text": "Download important insights from books and podcasts for offline listening"
                },
                {
                    "tag": "div",
                    "text": "Learn and grow together with BLINKIST Space."
                },

                {
                    "tag": "h4",
                    "text": "Plan the space (your learning playlist) to achieve your goals"
                },
                {
                    "tag": "div",
                    "text": "Share your space for free with anyone you want to learn with, even if they haven't joined Blinkist yet"
                },
                {
                    "tag": "div",
                    "text": "Enjoy a list of personalized recommendations and expert planning"
                },
                {
                    "tag": "div",
                    "text": "Always know what to read or listen to next - we will recommend titles based on your interests and goals"
                },
                {
                    "tag": "div",
                    "text": "Let professionals guide you through the topics that must be understood today, and immediately apply the knowledge you have learned through interactive tools and activities."
                },
                {
                    "tag": "div",
                    "text": "Discover new ideas and find motivation through expert curated recommended book lists"
                },
                {
                    "tag": "div",
                    "text": "Benefit from personal contact"
                },
                {
                    "tag": "h4",
                    "text": "Focus only on popular books - our editorial team carefully selects each book"
                },
                {
                    "tag": "div",
                    "text": "Listen to Blinks of Podcasts produced by podcast creators for exclusive new insights"
                },
                {
                    "tag": "div",
                    "text": "Enjoy expert crafted summaries of key ideas and be passionate about explaining complex topics in simple and elegant language News Report"
                },
                {
                    "tag": "div",
                    "text": "Blinkist is an interesting new application that encourages you to read more non fiction books... it's a fun and fascinating way to discover new interesting themes. '- The New York Times'"
                },
                {
                    "tag": "div",
                    "text": "Knowledge is never too much. If you want to broaden your horizons but don't have time to read, Blinkist is a fast and convenient way to learn. '- TheTextWeb Blinkist provides excellent service - great ideas are distilled to their essence. For those of us who are curious about new ideas for success, happiness, innovation, progress, and more, this is a great start. '- Forbes"
                },
                {
                    "tag": "div",
                    "text": "How will you upgrade?"
                }
            ]
        },
        {
            "id": 8,
            "name": "Scoopz",
            "free": true,
            "subTitle": [
                "Stories unfold, video by video"
            ],
            "languages":"English",
            "rate": 4.8,
            "image": "Scoopz.png",
            "tags": [
                "Every Moment", 
                "A Story Unfolds" ,
                "Be the Storyteller",
                "Simplified, Streamlined", 
                "Scoopz is Your New Favorite",
                "Uninterrupted Enjoyment"

            ],
            "desc": "Scoopz isn’t just about catching news; it's about diving into a sea of stories, all through the vivid and vibrant form of video. From your local scoop to global happenings, and the lifestyle tips in between, Scoopz brings the world closer to you, one clip at a time.",
            "content": [
                {
                    "tag": "h4",
                    "text": "Every Moment, A Story Unfolds"
                },
                {
                    "tag": "div",
                    "text": "Scoopz isn’t just about catching news; it's about diving into a sea of stories, all through the vivid and vibrant form of video. From your local scoop to global happenings, and the lifestyle tips in between, Scoopz brings the world closer to you, one clip at a time."
                },
                {
                    "tag": "h4",
                    "text": "Be the Storyteller"
                },
                {
                    "tag": "div",
                    "text": "With Scoopz, you're not just an audience; you're a creator. Share your video stories, offer insights, and unveil experiences that can inspire, inform, or simply entertain. It’s your platform to express what’s real and relevant to you and your community."
                },
                {
                    "tag": "h4",
                    "text": "Simplified, Streamlined"
                },
                {
                    "tag": "div",
                    "text": "Say goodbye to ads and hello to uninterrupted viewing. Scoopz is all about delivering a seamless video experience, letting you enjoy a world of content without distractions, just pure engagement."
                },
                {
                    "tag": "h4",
                    "text": "Why 'Scoopz' is Your New Favorite"
                },
                {
                    "tag": "div",
                    "text": "Vibrant Video News & Stories:"
                },
                {
                    "tag": "div",
                    "text": "Stay ahead with a diverse array of video content that spans news, community events, and enriching lifestyle topics."
                },
                {
                    "tag": "div",
                    "text": "Interactive and Immersive:"
                },
                {
                    "tag": "div",
                    "text": "Scoopz isn’t just for watching; it’s for participating. Share your own videos and become part of a larger narrative."
                },
                {
                    "tag": "div",
                    "text": "Tailored to Your Interests:"
                },
                {
                    "tag": "div",
                    "text": "Whether you're a news junkie or a lifestyle lover, find videos that cater to your curiosity and passions."
                },
                {
                    "tag": "div",
                    "text": "Uninterrupted Enjoyment:"
                },
                {
                    "tag": "div",
                    "text": "Experience video content the way it was meant to be — free from ads, free from fuss."
                },
                {
                    "tag": "div",
                    "text": "Dive Into the Visual Journey"
                },
                {
                    "tag": "div",
                    "text": "Download Scoopz now and immerse yourself in a community where video is the window to the world.  Discover, share, and engage with content that’s as dynamic as you are, all at your fingertips."
                }
            ]
        },

        {
            "id": 9,
            "name": "CapCut-Video Editor",
            "free": true,
            "subTitle": [
                "Bytedance Pte. Ltd"
            ],
            "languages":"English,Chinese",
            "rate": 4.8,
            "image": "CapCut.png",
            "tags": [
                "CapCut is a free, all-in-one video editing tool",
                "CapCut satisfies all video production needs",     
                "The Graphic Design Tool",
                "Basic Video Editing",  
                "Trending Effects & Filters","Effortless Sharing & Collaboration"
            ],
            "desc": "CapCut is a free, all-in-one video editing tool. It's packed with everything necessary to create high-quality, visually appealing videos and graphics.",
            "content": [
                {
                    "tag": "div",
                    "text": "CapCut is a free, all-in-one video editing tool. It's packed with everything necessary to create high-quality, visually appealing videos and graphics."
                },
                {
                    "tag": "div",
                    "text": "Offering both app and online version, CapCut satisfies all video production needs. Beyond fundamental video editing, styling and music, it includes advanced features like keyframe animation, buttery smooth slow-motion, smart stabilization, cloud storage, and multi-member editing - all free of charge."
                },
                {
                    "tag": "div",
                    "text": "Create stunning, easy to share videos with CapCut's unique features: trending styles, auto captions, text-to-speech, motion tracking, and background remover. Reveal your uniqueness and become a hit on TikTok, YouTube, Instagram, WhatsApp, and Facebook!"
                },
                {
                    "tag": "div",
                    "text": "FEATURES(available on both app and online versions):"
                },
                {
                    "tag": "h5",
                    "text": "Basic Video Editing"
                },
                {
                    "tag": "p",
                    "text": "- Trim, split, and merge videos with ease"
                },
                {
                    "tag": "p",
                    "text": "- Control video speed, rewind, or play in reverse"
                },
                {
                    "tag": "p",
                    "text": "- Infuse life into video clips with dynamic transitions and effects"
                },
                {
                    "tag": "p",
                    "text": "- Access unlimited creative video and audio assets"
                },
                {
                    "tag": "p",
                    "text": "- Personalize videos with diverse fonts, styles, and text templates",
                    "class": "mgbt10"
                },

                {
                    "tag": "h5",
                    "text": "Advanced Video Editing"
                },
                {
                    "tag": "p",
                    "text": "- Animate videos with keyframe animation"
                },
                {
                    "tag": "p",
                    "text": "- Achieve smooth slow-motion effects for your videos"
                },
                {
                    "tag": "p",
                    "text": "- Use Chroma key to eliminate specific video colors"
                },
                {
                    "tag": "p",
                    "text": "- Layer and splice videos using Picture-in-Picture (PIP)"
                },
                {
                    "tag": "p",
                    "text": "- Ensure smooth, steady footage with smart stabilization",
                    "class": "mgbt10"
                },
                {
                    "tag": "h5",
                    "text": "Special Features"
                },
                {
                    "tag": "p",
                    "text": "- Auto captions: Automate video subtitles with speech recognition"
                },
                {
                    "tag": "p",
                    "text": "- Background removal: Automatically exclude people from videos"
                },
                {
                    "tag": "p",
                    "text": "- Choose from thousands of templates for quick video output",
                    "class": "mgbt10"
                },
                {
                    "tag": "h5",
                    "text": "Trending Effects & Filters"
                },
                {
                    "tag": "p",
                    "text": "- Apply hundreds of trending effects to your videos, including Glitch, Blur, 3D, and more"
                },
                {
                    "tag": "p",
                    "text": "- Enhance your videos with cinematic filters and color adjustments",
                    "class": "mgbt10"
                },
                {
                    "tag": "h5",
                    "text": "Music & Sound Effects"
                },
                {
                    "tag": "p",
                    "text": "- Enrich videos with a vast library of music clips and sound effects"
                },
                {
                    "tag": "p",
                    "text": "- Sync your favorite TikTok music by signing in"
                },
                {
                    "tag": "p",
                    "text": "- Extract audio from video clips and recordings",
                    "class": "mgbt10"
                },
                {
                    "tag": "h5",
                    "text": "Effortless Sharing & Collaboration"
                },
                {
                    "tag": "p",
                    "text": "- Chromebook users can edit videos seamlessly with the online version, or use the app for editing on-the-go"
                },
                {
                    "tag": "p",
                    "text": "- Export custom resolution videos, including 4K 60fps and smart HDR"
                },
                {
                    "tag": "p",
                    "text": "- Adjust format for easy video sharing on TikTok and other platforms"
                }, {
                    "tag": "p",
                    "text": "- Enable online multi-member editing for collaborative video projects",
                    "class": "mgbt10"
                },
                {
                    "tag": "h5",
                    "text": "The Graphic Design Tool"
                },
                {
                    "tag": "p",
                    "text": "- Edit business visuals, commercial graphics, and social media thumbnails with ease"
                },
                {
                    "tag": "p",
                    "text": "- Leverage pro-level templates and AI-powered features for graphic design purposes"
                },
                {
                    "tag": "h5",
                    "text": "Cloud Storage"
                },
                {
                    "tag": "p",
                    "text": "- Easy backup and storage for various video and audio formats"
                },
                {
                    "tag": "p",
                    "text": "- Upgrade your plan for additional storage space, as needed",
                    "class": "mgbt10"

                },
                {
                    "tag": "div",
                    "text": "CapCut is a free, all-in-one video editing app. It has everything you need to create stunning and high-quality videos.  Offering both app and online version, CapCut satisfies all video production needs.  Beyond fundamental editing, styling and music, it includes advanced features like keyframe animation, buttery smooth slow-motion, chroma key, Picture-in-Picture (PIP), and stabilization - all free of charge."
                }

            ]
        },
        {
            "id": 10,
            "name": "RunwayML",
            "free": true,
            "subTitle": [
                  "Product bt runwayML"
            ],
            "languages":"English",
            "rate": 4.6,
            "image": "RunwayML.png",
            "tags": [
                "an interactive AI platform",             
                "provides the perfect platform",
                "most noteworthy for its design concept",
                "users can use machine learning to explore entirely new areas"
            ],
            "desc": "RunwayML is an interactive AI platform aimed primarily at creators and designers.",
            "content": [
                {
                    "tag": "div",
                    "text": "RunwayML is an interactive AI platform aimed primarily at creators and designers. It provides a number of creative AI tools and libraries that facilitate users to perform image and audio processing for an immersive experience. With RunwayML, users can use machine learning to explore entirely new areas, such as various types of visual and sound art, and generate new thinking and creation from an ai perspective"
                },
                {
                    "tag": "div",
                    "text": "The platform has a variety of types of models such as images, sounds, text, etc., which users can use to achieve better creative ideas. Users can either upload their own data for training, or they can directly use the trained model to add other innovative elements to their work. In addition, it also provides a friendly API and SDK to make the use of more convenient, more dimensional experience."
                },
                {
                    "tag": "div",
                    "text": "Among them, RunwayML is most noteworthy for its design concept, which is creative and open. Because it allows users to integrate models into their own applications, and to make modifications and special customizations to the code. This means that users not only have the power of machine learning, but also the ability to make everything a reality."
                },
                {
                    "tag": "div",
                    "text": "Overall, RunwayML is a perfect tool for combining creativity and machine learning, with an emphasis on openness, customizability, and a good user experience. Through this platform, users can very quickly add AI technology to their creative projects, so as to create more wonderful works. For creators and designers alike, RunwayML provides the perfect platform for an unparalleled interactive exploration of creativity and machine learning."
                },
                {
                    "tag": "h5",
                    "text": "Everything you need to make anything you want."
                },
                {
                    "tag": "div",
                    "text": "A novel type of creative suite is Runway. One where whatever you can conceive can be made and AI works as a collaborator."
                },
                {
                    "tag": "h5",
                    "text": "What does runway do?"
                },
                {
                    "tag": "div",
                    "text": "Runway is an AI magic tool that can help you generate creative videos, images, make the images infinitely scalable and automatically help you rebuild any graphics, or you can choose to train the AI yourself to get custom models"
                }
            ]
        },

        {
            "id": 11,
            "name": "Zoom Workplace",
            "free": true,
            "subTitle": [
                "Product by Zoom AI Companion"
            ],
            "languages":"English",
            "rate": 4.6,
            "image": "ZoomWorkplace.png",
            "tags": ["Simplify communication","Improve work efficiency"],
            "desc": "Zoom is an internationally renowned cloud video conferencing enterprise.",
            "content": [
                {
                    "tag": "div",
                    "text": "Zoom is an internationally renowned cloud video conferencing enterprise. We have unified mobile platform based collaboration, cloud based video conferencing, and online conferencing into a simple and easy-to-use application software. Let's hold or participate in a high-definition, face-to-face cloud video conference now that supports high-quality screen sharing! You can do it anytime, anywhere, on any device."
                },
                {
                    "tag": "div",
                    "text": "Zoom was the champion of the 2013 Influencer's Award in the United States and also the winner of the Community Choice Award for Best Application.  It is extremely easy to use, free to download and install, then click on 'Hold Meeting' and you will be able to invite up to 100 people to attend the meeting!"
                },
                {
                    "tag": "h5",
                    "text": "Main functions:"
                },
                {
                    "tag": "p",
                    "text": "-The best Android video conferencing quality"
                },
                {
                    "tag": "p",
                    "text": "-Support group chat on Android phones, sending text messages, pictures, and sounds"
                },
                {
                    "tag": "p",
                    "text": "-Conveniently invite mobile contacts or invite other contacts via email"
                },
                {
                    "tag": "p",
                    "text": "-Supports WiFi, 4G/LTE, and 3G networks"
                },
                {
                    "tag": "p",
                    "text": "-Safe driving mode"
                },
                {
                    "tag": "p",
                    "text": "-Simultaneously supports joining meetings through iPad, iPhone, Android devices, Windows, Mac, hardware video conferencing systems, phone lines, and other methods"
                },
                {
                    "tag": "p",
                    "text": "-H323 full-featured support: video wall, join the meeting before the host, add password verification, P2P, PRC proxy"
                }
            ]
        },
        {
            "id": 12,
            "name": "Indeed Jobs",
            "free": true,
            "subTitle": [
                "Product by Indeed Jobs"
            ],
            "languages":"English,German,Chinese,French etc",
            "rate": 4.8,
            "image": "IndeedJobs.png",
            "tags": [
                "The world's most powerful job search engine",        
                "helping you find a job",
                "The world's top job search website*",
                "Job search",
                "Personalized settings",          
                "Job Information"
            ],
            "desc": "With a simple search, you can find millions of job positions for free on various recruitment websites and corporate websites.",
            "content": [
                {
                    "tag": "div",
                    "text": "Indeed job search"
                },
                {
                    "tag": "div",
                    "text": "Regarding this application"
                },
                {
                    "tag": "div",
                    "text": "The world's most powerful job search engine, helping you find a job!"
                },
                {
                    "tag": "div",
                    "text": "With a simple search, you can find millions of job positions for free on various recruitment websites and corporate websites.From search to application, Indeed job search helps job seekers find the best job."
                },
                {
                    "tag": "h5",
                    "text": "The world's top job search website*"
                },
                {
                    "tag": "p",
                    "text": "-Can search for jobs in over 60 countries and 28 languages worldwide"
                },
                {
                    "tag": "p",
                    "text": "-A database with over 16 million job positions Job search"
                },
                {
                    "tag": "p",
                    "text": "-Simple and fast job search experience"
                },
                {
                    "tag": "p",
                    "text": "-Find nearby positions through GPS positioning"
                },
                {
                    "tag": "p",
                    "text": "-View updated positions since your last visit"
                },
                {
                    "tag": "p",
                    "text": "-You can search for job titles, company names, and the location where you wish to apply for a job"
                },
                {
                    "tag": "p",
                    "text": "-Multiple job types can be found, including full-time, part-time, contract workers, fresh graduates, interns, etc Apply for a position"
                },
                {
                    "tag": "p",
                    "text": "-Apply for positions easily and quickly with your 'Indeed resume'"
                },
                {
                    "tag": "p",
                    "text": "-Create a resume and immediately become a part of Indeed's global database of 70 million resumes"
                },
                {
                    "tag": "p",
                    "text": "-Filter out positions that can be applied for on mobile phones"
                },
                {
                    "tag": "p",
                    "text": "-Don't want to submit your resume right away? Set a reminder to apply later!",
                    "class": "mgbt10"
                },
                {
                    "tag": "h5",
                    "text": "Personalized settings"
                },
                {
                    "tag": "p",
                    "text": "-Save or forward your favorite work via email"
                },
                {
                    "tag": "p",
                    "text": "-Free registration for Indeed account available"
                },
                {
                    "tag": "p",
                    "text": "-Follow your favorite company and receive notifications as soon as there are new positions available"
                },
                {
                    "tag": "p",
                    "text": "-Automatically send updated recruitment information to your email every day"
                },
                {
                    "tag": "p",
                    "text": "-Search for the positions you have viewed and saved"
                },
                {
                    "tag": "h5",
                    "text": "Job Information"
                },
                {
                    "tag": "p",
                    "text": "-Preview the complete job description"
                },
                {
                    "tag": "p",
                    "text": "-Review the ratings given by current and former employees to various companies"
                },
                {
                    "tag": "p",
                    "text": "-View reviews written by over 12 million employees for various companies"
                },
                {
                    "tag": "p",
                    "text": "-View the working environment of the recruiting company through photos"
                },
                {
                    "tag": "p",
                    "text": "-Comment on the company you work for"
                }
            ]
        },
        {
            "id": 13,
            "name": "Threads",
            "free": true,
            "subTitle": [
                "Product by Threads",
                "2023"
            ],
            "languages":"English",
            "rate": 4.6,
            "image": "Threads.png",
            "tags": [
                "Use Threads to Express More Information",
                "Instagram's Text Based Dialogue Application",
                "Share your perspective",
                "Don't miss any moment",
                "Control the conversation",
                "Share your perspective"
            ],
            "desc": "Threads is described as 'Instagram's text-based conversation app' that allows users to post updates, share text, images and videos, and interact with other users through likes, retweets and replies.",
            "content": [
                {
                    "tag": "div",
                    "text": "Use Threads to Express More Information - Instagram's Text Based Dialogue Application."
                },
                {
                    "tag": "div",
                    "text": "The theme is a place where communities gather to discuss everything, from the topics you care about today to tomorrow's trends.   No matter what interests you, you can follow your favorite creators and other people who love the same things and directly connect, or establish your own loyal followers to share your ideas, perspectives, and creativity with the world."
                },
                {
                    "tag": "h4",
                    "text": "You can perform some operations on Threads"
                },
                {
                    "tag": "h5",
                    "text": "Visit your Instagram followers"
                },
                {
                    "tag": "p",
                    "text": "Your Instagram username and verification badge are reserved for you.    Just a few taps to automatically follow the same account you follow on Instagram and discover new accounts."
                },
                {
                    "tag": "h4",
                    "text": "Share your perspective"
                },
                {
                    "tag": "p",
                    "text": "Start a new thread to express your ideas.     This is your own space, you can control who can reply"
                },
                {
                    "tag": "p",
                    "text": "Connect with friends and your favorite creators",
                    "class": "mgbt10"
                },
                {
                    "tag": "div",
                    "text": "Jump to replies to participate in actions and respond to comments, humor, and insights from creators you know and love.     Find your community and connect with people who care about things that interest you."
                },
                {
                    "tag": "h4",
                    "text": "Control the conversation"
                },
                {
                    "tag": "div",
                    "text": "Customize your settings and use controls to manage who can view your content, reply to your posts, or mention you.     The account you blocked will inherit from Instagram, and we are implementing the same community guidelines to help ensure that everyone interacts safely and authentically."
                },
                {
                    "tag": "h4",
                    "text": "Search for ideas and inspiration"
                },
                {
                    "tag": "p",
                    "text": "From TV recommendations to career advice, seek answers to questions or learn new knowledge from crowdsourcing conversations, thought leaders, and industry experts."
                },
                {
                    "tag": "h4",
                    "text": "Don't miss any moment"
                },
                {
                    "tag": "div",
                    "text": "Master the latest trends and on-site activities.     Whether it's about new music, movie premieres, sports, games, TV shows, fashion, or the latest product releases, as long as your favorite profile starts a new topic, you can search for discussions and receive notifications."
                }
            ]
        }
    ]
}
