<template>
    <ul class="rateContainer">
        <!-- <input type="text" v-model=""> -->
        <li v-for="(item,index) in rateOption" :key="index"> 
            <div class="rate-title">
                <span><van-icon :name="item.icon" size="14" color="#919191"/></span>
                <span>{{item.name}}</span>
            </div>
            <div class="progress" ref="progressRef">
                <span v-for="it in totalStep" :key="it"></span>
            </div>
        </li>
    </ul>
</template>
<script setup>
    import { onMounted, ref,defineProps } from 'vue';
        // name: 'MyRate',
       const props = defineProps({
         
            totalStep:{//总格子数量
                type:Number,
                default:()=>(5)
            },
            // currentStep:{//要展示的格子数量
            //     type:Number,
            //     default:()=>(3.5)
            // },
            rateData:{
                type:Object,
                default:()=> ({
                    rate:5
                })
            },
            rateOption: {
                type: Array,
                default: ()=>([
                {
                    name:'Reviews',
                    icon: 'comment'
                    // size: 14 ,
                    // color: '#919191'
                },{
                    name:'Employees',
                    icon: 'manager'
                },{
                    name:'Popularity',
                    icon: 'fire'
                },{
                    name:'Social Media',
                    icon: 'cluster'
                }
            ])
            }
        })
        const progressRef = ref()
        onMounted(()=>{
            if(progressRef.value){
                progressRef.value.forEach(item=>{
                    initProgress(5,item)
                })
            
            }

        })
        const initProgress = (cubeCount,dom)=>{
             //算出当前格子的比率
            let ratio = (props.rateData.rate / props.totalStep);

            // 根据格子的比率算出当前的数值占了几个格子
            let nowCubeCount = (cubeCount * ratio)  
            // console.log(nowCubeCount,'nowCubeCount')
            // let cubeDomArr = progressRef.value.children;  
            let cubeDomArr = dom.children;  
            // 比较渐变色处理，只取了第一个格子和最后一个格子的颜色,利用数组计算差值 
            let startColor=[36,191,255]; 
            let endColor=[1,86,255];
            let perDiffColor=[];
            // 这里用结束时的颜色减掉开始时的颜色得到总色差
            // 然后除以当前的格子数，分成更小的色差值，保留三位小数，并转为数字
            // 然后将每一个格子对应的颜色差值存到perDiffColor数组
            for(let i=0;i<endColor.length;i++){
                perDiffColor.push( Number(((endColor[i]-startColor[i])/nowCubeCount).toFixed(3)))
            }
            for(let i=0;i<nowCubeCount;i++){
                cubeDomArr[i].style.background=
                `RGB(
                ${startColor[0]+i*perDiffColor[0]},
                ${startColor[1]+i*perDiffColor[1]},
                ${startColor[2]+i*perDiffColor[2]})
                `
            }
        }
       
</script>
<style lang="less" scoped>
    .rateContainer{
        // width: 100%;
        width:70%;
        margin:0 auto;
        li{
            margin-bottom: 10px;
        }
        .rate-title{
            // border:1px solid red;
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            font-size: 0;
            span{
                // border:1px solid red;
                &:last-child{
                    margin-left: 6px;
                    font-size: 12px;
                    // line-height: 10px;
                    color: #333;
                }
            }
        }
        .progress{
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 8px;
            span{
                width:18%;
                background: #F1F1F1;
                transition: background-color 0.3s ease, width 0.3s ease;
                // background:linear-gradient(90deg, rgba(36,191,255, 1) 0%, rgba(1,86,255, 1) 100%);
              
            }
        }
    }
</style>